import React from "react";
import ProfileCarousel from "../components/ProfileCarousel";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import { SectionTitleWithIntro } from "../common/BoxAbstraction";
import {
  ComponentCard,
  ComponentCardNoColor,
} from "../common/PaperAbstraction";
import { margin } from "@mui/system";
import Divider from "@mui/material/Divider";

export default function ProfileAnalysis(props) {
  return (
    <ComponentCardNoColor
      square={true}
      sx={{ marginTop: -2, marginBottom: 2, paddingTop: 1, marginX: 1 }}
      elevation={0}
    >
      <Grid container rowSpacing={0}>
        <Grid item xs={12}>
          <SectionTitleWithIntro
            {...props.profileAnalysisIntro}
            marginTop={0}
          />
        </Grid>
        <Grid item xs={12}>
          <ProfileCarousel
            carouselData={props.carouselData}
            assetPath={props.assetPath}
          />
        </Grid>
      </Grid>
    </ComponentCardNoColor>
  );
}
