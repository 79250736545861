import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/system/Box";
import { Avatar, Button, Divider, Paper } from "@mui/material";
import DiscreteSliderValues from "./DiscreteSliderValues";
import SummaryTable from "./SummaryTable";
import RangeTable from "./RangeTable";
import { useTheme } from "@mui/material/styles";
import {
  CenterItems,
  CoveringCard,
  LeftItems,
  RightItems,
} from "../common/PaperAbstraction";
import { ReadMore } from "../common/TypographyAbstraction";
import Grid from "@mui/material/Grid";
import { RecommendationItem } from "./RecommendSection";
import { useState, useRef, useEffect } from "react";
import parse from "html-react-parser";
import CircleIcon from "@mui/icons-material/Circle";
import {
  SectionTitleWithIntro,
  SectionSubTitleWithIntro,
} from "../common/BoxAbstraction";
import InfoIcon from "@mui/icons-material/Info";

function GenerateRangeVisualisation(props) {
  if (!props?.sliderType) return <></>;
  if (props.sliderType == "STRATIFIED_RANGES") return <RangeTable {...props} />;
  return <DiscreteSliderValues {...props} />;
}

function GenerateBiomarkerCard(props) {
  // console.log(
  //   "🚀 ~ file: ProfileCard.js:30 ~ GenerateBiomarkerCard ~ props",
  //   props
  // );
  const biomarkerRef = useRef(null);
  const signal = {
    color: "#00000000",
    text: "",
  };
  if (props.signalText == "normal") {
    signal.color = "green";
    signal.text = "Normal";
  } else if (props.signalText == "high") {
    signal.color = "red";
    signal.text = "High";
  } else if (props.signalText == "abnormal") {
    signal.color = "red";
    signal.text = "Abnormal";
  } else if (props.signalText == "low") {
    signal.color = "red";
    signal.text = "Low";
  }
  useEffect(() => {
    if (props.expanded && props.simpleSlug === props?.inView?.testSlug) {
      // console.log("well here we are 2",biomarkerRef)
      setTimeout(() => {
        biomarkerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 400);
    }
  }, [props.inView]);
  return (
    <>
      <Grid item xs={9} id={props.testNameSlug} ref={biomarkerRef}>
        <Typography variant="h5">
          {props.testName}: {props.testResultValue}
        </Typography>
      </Grid>
      <Grid item xs={3} alignItems="flex-end">
        <RightItems elevation={0} sx={{ bgcolor: "inherit" }}>
          <CircleIcon
            sx={{
              height: 20,
              width: 20,
              padding: 0,
              margin: 0,
              color: { color: `${props?.signalColor}`, opacity: "1" },
            }}
          />
          <Typography variant="h6" marginLeft={1}>
            {signal.text}
          </Typography>
        </RightItems>
      </Grid>
      <GenerateRangeVisualisation
        {...props?.rangeObj}
        resultValue={props?.testResultValue}
        signalColor={props?.signalColor}
      />
      <Grid item xs={12}>
        <Typography variant="body1">{parse(props.text)}</Typography>
      </Grid>
      <Divider style={{ width: "97%", marginTop: 12 }} variant="middle" />
    </>
  );
}

function GenerateTips(props) {
  // console.log(
  //   "🚀 ~ file: ProfileCard.js ~ line 187 ~ GenerateTips ~ props",
  //   props
  // );
  return (
    <Grid container spacing={2} marginTop={1}>
      <Grid item xs={12}>
        <Box margin={0}>
          <Typography variant="h4">{props.header}</Typography>
        </Box>
      </Grid>
      {props.text.map((item, index) => (
        <RecommendationItem
          key={index}
          text={item}
          image={props.svg[index]}
          assetPath={props.assetPath}
        />
      ))}
      {/* // <RecommendationItem text="Blood Count Recommendation dsadsa add" /> */}
    </Grid>
  );
}

function useArrayRef() {
  const refs = [];
  return [refs, (el) => el && refs.push(el)];
}

function GenerateProfileSvg(assetPath, profileSvg) {
  console.log(
    "🚀 ~ file: ProfileCard.js:141 ~ GenerateProfileSvg ~ profileSvg:",
    profileSvg
  );
  // if (profi)
  if (!profileSvg)
    return (
      <Box width={50} sx={{ bgcolor: "inherit" }}>
        <InfoIcon variant="square" sx={{ width: 45, height: 45 }} />
      </Box>
    );
  return (
    <Box width={50} sx={{ bgcolor: "inherit" }}>
      <img
        src={`${assetPath}${profileSvg}`}
        alt="temp"
        variant="square"
        style={{
          maxHeight: 45,
          maxWidth: 45,
        }}
      />
    </Box>
  );
}

export default function ProfileCard(props) {
  const theme = useTheme();

  const [expand, setExpand] = useState(false);
  const [expandInner, setExpandInner] = useState(false);
  const accordElem = useRef(null);

  const [elements, ref] = useArrayRef();

  const handleClickEdit = () => {
    setExpand(!expand); // "0" here is as defined in your Accordion.Collapse eventKey
    accordElem.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    }); // initiate scroll to the "AddMock" Accordion component
  };
  // if (props?.profile?.simpleProfileView) return SimpleProfile(...props);
  function accordianOnClick() {
    setExpand(!expand);
    window.dataLayer.push({ gtmId: "GTM-TKFBQPK", event: "accordianOpened" });
  }

  useEffect(() => {
    // if (props.expandedIndex !== props.profileIndex && expand) {
    //   setExpand(false);
    // }
    if (
      (props?.inView?.profileNameSlug === props.profile.profileNameSlug ||
        props.linkExpand === props.profile.profileNameSlug) &&
      !expand
    ) {
      setExpand(true);
      // console.log(
      //   "🚀 ~ file: ProfileCard.js:150 ~ useEffect ~ profileNameSlug",
      //   ""
      // );

      setTimeout(() => {
        accordElem.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 350);
      // console.log("🚀 ~ file: ProfileCard.js:135 ~ useEffect ~ accordElem", accordElem)
      // if(props.expandedIndex){
      //   console.log("🚀 ~ file: ProfileCard.js:130 ~ useEffect ~ props.expandedIndex", props.expandedIndex);
      //   console.log(elements)   ;
      // }
    }
  }, [props.linkExpand, props.inView, props.expandedIndex]);

  const ProfileSvg = GenerateProfileSvg(props?.assetPath, props?.profile?.svg);

  return (
    <Accordion
      expanded={expand}
      TransitionProps={{ unmountOnExit: true }}
      ref={accordElem}
      key={props?.profile?.profileNameSlug}
      id={props?.profile?.profilseNameSlug}
      sx={{ bgcolor: "inherit" }}
      // transiti
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          setExpand(!expand);
          props.setExpandedIndex(props.profileIndex);
        }}
        className="accordian-summary-class"
      >
        <Box elevation={0} paddingX={2} sx={{ width: "100%" }}>
          <LeftItems
            elevation={0}
            sx={{
              marginLeft: "20px",
              bgcolor: "inherit",
            }}
          >
            {/* <<Box width={50} sx={{ bgcolor: "inherit" }}>
              <img
                src={`${props.assetPath}${props?.profile?.svg}`}
                alt="temp"
                variant="square"
                style={{
                  maxHeight: 45,
                  maxWidth: 45,
                }}
              />
            </Box>> */}
            {ProfileSvg}
            <Typography
              variant="h2"
              sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
            >
              {props?.profile?.name}
            </Typography>
          </LeftItems>
          {!props?.profile?.simpleProfileView && (
            <CoveringCard
              elevation={0}
              sx={{
                bgcolor: theme.palette.other.profileCardHighlight,
                width: "100%",
                paddingY: 1,
                margin: 1,
              }}
            >
              <Grid container rowSpacing={0} sx={{ padding: 0, margin: 0 }}>
                <Grid item xs={6}>
                  <CenterItems
                    elevation={0}
                    sx={{ bgcolor: "inherit", padding: 0, margin: 0 }}
                  >
                    <Typography variant="body2">Normal:</Typography>
                    <Typography
                      variant="body1"
                      color={theme.palette.other.slider.normal}
                      fontWeight={700}
                      paddingLeft={1}
                    >
                      {props?.profile?.normalBiomarkerCount}
                    </Typography>
                  </CenterItems>
                  <Divider
                    orientation="vertical"
                    style={{ marginTop: -22, height: "100%" }}
                  />
                </Grid>
                {/* <Grid item xs={1} >

              </Grid> */}
                <Grid item xs={6}>
                  <CenterItems
                    elevation={0}
                    sx={{ bgcolor: "inherit", padding: 0, margin: 0 }}
                  >
                    <Typography variant="body2"> Abnormal:</Typography>
                    <Typography
                      variant="body1"
                      color={theme.palette.other.slider.abnormal}
                      fontWeight={700}
                      paddingLeft={1}
                    >
                      {props?.profile?.abnormalBiomarkerCount}
                    </Typography>
                  </CenterItems>
                </Grid>
              </Grid>
            </CoveringCard>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className="profile-accordion">
        <Box marginX={1}>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box marginY={2}>
                <SummaryTable
                  tableData={props.biomarker}
                  simpleTable={props?.profile?.simpleTableView || false}
                  sx={{ bgcolor: "inherit" }}
                />
              </Box>
            </Grid>
            <Divider style={{ width: "97%" }} variant="middle" />
          </Grid>
          {/* <Accordion
            console.log("🚀 ~ file: ProfileCard.js:272 ~ useEffect ~ setExpand", setExpand)
            console.log("🚀 ~ file: ProfileCard.js:272 ~ useEffect ~ true", true)
            console.log("🚀 ~ file: ProfileCard.js:272 ~ useEffect ~ true", true)
            // elevation={0}
            sx={{
              border: "none",
              width: "100%",
              padding: "0",
              margin: "0",
              bgcolor: "inherit",
            }}
            disableGutters={true}
            disableSpacing={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setExpandInner(!expandInner)}
            >
            </AccordionSummary>
          <AccordionDetails> */}
          {/* <Box margin={0} marginTop={2}>
            <Typography variant="h4">Your tests explained</Typography>
          </Box> */}
        </Box>

        {!props?.profile?.simpleProfileView && (
          <Box marginX={1}>
            <SectionSubTitleWithIntro
              title="Your tests explained"
              text="💡 A brief overview of the key findings and main conclusions drawn from the test results are provided to you with the best visualisation."
            />

            <Grid container spacing={2}>
              {props?.biomarker?.map((biomarker, index) => (
                <GenerateBiomarkerCard
                  key={index}
                  ref={ref}
                  {...biomarker}
                  expanded={expand}
                  inView={props.inView}
                  setInView={props.setInView}
                />
              ))}
            </Grid>
            {/* </AccordionDetails>
          </Accordion> */}
            <GenerateTips
              {...props?.profile?.data?.tips}
              assetPath={props.assetPath}
            />
            <Box width={"100%"} padding={0} margin={0}>
              <Button onClick={handleClickEdit} fullWidth={true}>
                Show Less
              </Button>
            </Box>
            {/* <Box width={"100%"} padding={0} margin={0}>
            <Typography align="center">End Of Profile</Typography>
          </Box> */}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
