export default function CarouselDataHelper(input) {
  const arr = input.map((item, index) => {
    const obj = {};
    obj.name = item?.profile.name;
    obj.svg = item?.profile.svg;
    obj.slug = item?.profile.profileNameSlug;
    return obj;
  });
  return arr;
}
