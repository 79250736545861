import React from "react";
import Paper from "@mui/material/Paper";
import ProfileCard from "./ProfileCard";
import { ComponentCard } from "../common/PaperAbstraction";
import { SectionTitleWithIntro } from "../common/BoxAbstraction";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import { useLayoutEffect, useState, useEffect,useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { HashLink } from "react-router-hash-link";
import { styled, lighten, darken } from '@mui/system';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});


export default function AllProfile(props) {
  // console.log("🚀 ~ file: AllProfile.js:16 ~ AllProfile ~ props", props)
  const [linkExpand, setLinkExpand] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [inView, setInView] = useState({ profileNameSlug: "", testSlug: "" });
  const [profileExpanded, setProfileExpanded] = useState(false);
  const history = useLocation();
  // console.log("🚀 ~ file: AllProfile.js:39 ~ AllProfile ~ history", history)
  const theme = useTheme();

  useEffect(() => {
    // console.log(history.hash);
    const urlHash = history?.hash?.split("#")[1];
    // console.log(
    //   "🚀 ~ file: AllProfile.js ~ line 18 ~ useEffect ~ urlHash",
    //   urlHash
    // );

    let expandProfile = urlHash?.split("_")[0];
    let expandIndex = urlHash?.split("_")[1];

    // try {
    //   setExpandedIndex(parseInt(expandIndex));
    // } catch {}
    // console.log(
    //   "🚀 ~ file: AllProfile.js ~ line 26 ~ useEffect ~ expandIndex",
    //   expandIndex
    // );
    async function goIntoView() {
      if (urlHash != "") await setLinkExpand(expandProfile);
      // if (expandIndex != "") await setExpandedIndex(expandIndex);
    }
    goIntoView();
  }, [history.hash]);

  // useEffect(() => {
  //   console.log(history.hash);
  //   const urlHash = history?.hash?.split("#")[1];
  //   if (urlHash != "") setLinkExpand(urlHash);
  // }, [history.hash]);

  // useLayoutEffect(() => {
  //   const anchor = window.location.hash.split("#")[1];
  //   if (anchor) {
  //     setLinkExpand(anchor);
  //     console.log(
  //       "🚀 ~ file: AllProfile.js ~ line 16 ~ useLayoutEffect ~ anchor",
  //       anchor
  //     );
  //     // const accExists = faqQuestions.find((q) => q.accordionId === anchor);
  //     // if (accExists) {
  //     // setExpandend(anchor);
  //     // const anchorEl = document.getElementById(anchor);
  //     // anchorEl.scrollIntoView();
  //     // }
  //   }
  // }, []);

  const options = props.autoComplete;
  return (
    <>
      <ComponentCard
        square={true}
        elevation={0}
        sx={{ bgcolor: theme.palette.other.cardBackground, marginX: 1 }}
      >
        <SectionTitleWithIntro {...props.allProfileIntro} paddingBottom={2} />
        <Autocomplete
          className="search"
          id="grouped-demo"
          options={options}
          groupBy={(option) => option.profileName}
          getOptionLabel={(option) => option.name}
          sx={{ maxWidth: "450px", paddingX: 4, paddingY: 2 }}
          renderInput={(params) => (
            <TextField {...params} label="Search Parameters" />
          )}
          renderGroup={(params) => {
            // console.log("🚀 ~ file: AllProfile.js:128 ~ AllProfile ~ params", params);
            return (
              <li>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            );
          }}
          onChange={(event, value) => {
            // console.log(
            //   "🚀 ~ file: AllProfile.js:112 ~ AllProfile ~ value",
            //   value
            // );
            // console.log("🚀 ~ file: AllProfile.js:129 ~ AllProfile ~ event", event)
            if (event.type === "keydown" || event.type === "click") {
              setInView({
                profileNameSlug: value?.profileNameSlug,
                testSlug: value.slug,
              });
            }
            return;
          }}
        />
      </ComponentCard>
      <ComponentCard
        square={true}
        elevation={0}
        sx={{ bgcolor: theme.palette.other.cardBackground }}
      >
        {props.profileData.map((profile, index) => (
          <ProfileCard
            key={`${index}`}
            {...profile}
            linkExpand={linkExpand}
            assetPath={props.assetPath}
            expandedIndex={expandedIndex}
            setExpandedIndex={setExpandedIndex}
            profileIndex={index}
            // biomarkerRefs={biomarkerRefs}
            inView={inView}
            setInView={setInView}
          />
        ))}
        {/* <ProfileCard {...props.profileData[0]} /> */}
      </ComponentCard>
    </>
  );
}
