import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { CenterItems, CoveringCard } from "../common/PaperAbstraction";
import { SectionTitleWithIntro } from "../common/BoxAbstraction";
import UpsellCard from "./UpsellCard";
import ProfilePanelOverviewCardsDonut from "./ProfilePanelOverviewCardsDonut";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

export default function FollowUpRecommendation(props) {
  // console.log(
  //   "🚀 ~ file: FollowUpRecommendation.js:15 ~ FollowUpRecommendation ~ props",
  //   props
  // );
  const theme = useTheme();
if(props?.followUpRecommendation?.length==0||!props?.followUpRecommendation)
  return <></>;
  return (
    <Paper
      square={true}
      elevation={0}
      // sx={{ bgcolor: theme.palette.other.cardBackground }}
    >
      <SectionTitleWithIntro {...props.followUpRecommendationIntro} />

      <CoveringCard elevation={0}>
        <Stack spacing={2}>
          {props.followUpRecommendation.map((concernArea, index) => (
            <UpsellCard
              key={`${index}`}
              {...concernArea}
              assetPath={props.assetPath}
              lastIndex={index === props.concernAreaData - 1}
            />
          ))}
        </Stack>
      </CoveringCard>
      <Divider style={{ width: "100%", height: "2px" }} variant="left" />
    </Paper>
  );
}
