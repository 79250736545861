import React from "react";
import Typography from "@mui/material/Typography";
import {
  CenterItems,
  ComponentCard,
  CenterItemsColumn,
  ComponentCardNoColor,
} from "../common/PaperAbstraction";
import { Paper, Button, Box } from "@mui/material";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import FemaleIcon from "@mui/icons-material/Female";
import { Avatar, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { HashLink } from "react-router-hash-link";

// export default function ProfileCarousel() {
//   return (
//     <ComponentCard
//       square={true}
//       elevation={0}
//       sx={{ bgcolor: "#fff", height: "12vh" }}
//     >
//       Carousel
//     </ComponentCard>
//   );
// }

const responsive = {
  desktop: {
    breakpoint: { max: 1024, min: 720 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 720, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

function CarouselItem(props) {
  // console.log(
  //   "🚀 ~ file: ProfileCarousel.js ~ line 46 ~ CarouselItem ~ props",
  //   props
  // );
  return (
    <HashLink smooth to={`#${props.slug}`}>
      <CenterItems
        sx={{
          padding: 1,
          margin: 1,
          height: "85px",
        }}
        elevation={1}
        variant="outlined"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CenterItems elevation={0} sx={{}}>
              <Box>
                <img
                  src={`${props.assetPath}${props?.svg}`}
                  height="50px"
                  alt="temp"
                  variant="square"
                  style={{ maxHeight: 45, maxWidth: 45 }}
                />
              </Box>
            </CenterItems>
            <CenterItems elevation={0}>
              <Typography variant="carouselText">{props?.name}</Typography>
            </CenterItems>
          </Grid>
        </Grid>
      </CenterItems>
    </HashLink>
  );
}

export default function ProfileCarousel(props) {
  // const deviceType = vw > 720 ? "desktop" : "mobile";
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    dots: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <ComponentCardNoColor square={true} elevation={0}>
      <Slider {...settings}>
        {props.carouselData.map((profile, index) => (
          <CarouselItem key={index} {...profile} assetPath={props.assetPath} />
        ))}
        {/* <CarouselItem {...props.carouselData[0]} /> */}
      </Slider>
    </ComponentCardNoColor>
  );
}
