import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";

function createData(name, result, unit, range, status,signalColor) {
  return { name, result, unit, range, status ,signalColor};
}

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

function CreateRow(props) {
  let indicatorColor = props.signalColor;
  // if (props.status === "normal") indicatorColor = "green";
  // else if (props.status == "finalCritical") indicatorColor = "red";
  // else if (props.status == "finalCritial") indicatorColor = "red";
  // else if (props.status == "oneFromNormal") indicatorColor = "orange";
  // else if (props.status == "twoFromNormal") indicatorColor = "orange";
  // else indicatorColor = "#00000000";
  return (
    <TableRow
      key={props.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        height: "35px",
      }}
    >
      <TableCell align="center" padding="none" margin="none">
        <CircleIcon
          sx={{
            height: 15,
            width: 15,
            padding: 0,
            margin: 0,
            color: { color: indicatorColor },
          }}
          padding={0}
          margin={0}
        />
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <Typography variant="body3">{props.name}</Typography>
      </TableCell>
      <TableCell align="right" padding="none">
        <Typography variant="body3">{props.result}</Typography>
      </TableCell>
      {!props?.simpleTable && (
        <>
          <TableCell align="right" padding="none">
            <Typography variant="unit">{props.unit}</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="body3">{props.range}</Typography>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

export default function SummaryTable(props) {
  const rows = props?.tableData?.map((item) =>
    createData(
      item.displayName,
      item.testResultValue,
      item.testMeasuringUnit,
      item.displayRange,
      item.colorIndicator,
      item.signalColor
    )
  );

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      width={"100%"}
      sx={{ bgcolor: "inherit" }}
    >
      <Table
        sx={{ minWidth: 300, bgcolor: "inherit" }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell padding="none">
              <Typography variant="h6">Test Name</Typography>
            </TableCell>
            <TableCell align="right" padding="none">
              <Typography variant="h6">Result</Typography>
            </TableCell>
            {!props?.simpleTable && (
              <>
                <TableCell align="right" padding="none">
                  <Typography variant="unit">Unit</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">Range</Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <CreateRow key={index} {...row} simpleTable={props?.simpleTable} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
