import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import LogoAppBar from "./components/LogoAppBar";
import Introsection from "./components/Introsection";
import SwitchView from "./components/SwitchView";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import { ComponentCard } from "./common/PaperAbstraction";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  profileDataHelper,
  bodySummaryHelper,
  recommendationDataHelper,
  concernAreaDataHelper,
  autoCompleteHelper,
  followUpRecommendationDataHelper,
  patientDetailsDataHelper,
} from "./utils/DataHelper";
import CarouselDataHelper from "./utils/CarouselDataHelper";
// import fetch from "node-fetch";
// import got from "got";
import { Typography } from "@mui/material";
import niroggyanLogo from "./assets/niroggyanBlue.png";
import BackdropLoader from "./components/BackdropLoader";
import { CenteredBox } from "./common/BoxAbstraction";
import { useLocation } from "react-router-dom";
import inputSample from "./test.json";

async function getInputData(id) {
  if (!id)
    return {
      reportData: {
        patientName: "-",
        paitentAge: "-",
        paitentGender: "M",
        dob: "-",
        referredBy: "-",
        labId: "-",
        sampleId: "-",
      },
      recommendationData: [],
      resultData: [],
    };
  const response = await fetch(
    " https://o6c7glsyu1.execute-api.eu-west-2.amazonaws.com/prod/optimised-viz-app",
    {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
    }
  );
  const data = await response.json();
  // const data = inputSample;
  return data;
}

const clientSpecificSettings = {
  palette: {
    primary: {
      main: "#0d6efd",
    },
    // secondary: {
    //   main: "#fbe1df",
    // },
    other: {
      switchView: "#fff",
      profileCardHighlight: "#3567D622",
      introScetion: "#E8F1FA",
      bodySummary: "#E5E4E2",
      // cardBackground: "#E3E9F51A",
      bodyFill: "#F6F5FB",
      navBarColor: "#fff",
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#003494",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "#3367c7",
        },
      },
    },
  },
  typography: {
    h2: {
      fontSize: "1.6rem",
      fontWeight: 700,
      lineHeight: 2,
      color: "#3A454A",
    },
    h3: {
      fontSize: "1.6rem",
      fontWeight: 700,
      lineHeight: 2,
      color: "#151A22",
    },
    h4: {
      fontSize: "1.3rem",
      fontWeight: 700,
      lineHeight: 2,
      color: "#3A454A",
    },
    h5: {
      fontSize: "1.0rem",
      fontWeight: 500,
      lineHeight: 1,
      color: "#787A86",
    },

    h6: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#6E6E70",
    },

    carouselText: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#787A86",
    },
    body1: {
      fontSize: "0.9rem",
      fontWeight: 400,
      color: "#6E6E70",
    },
    p: {
      fontSize: "0.9rem",
      fontWeight: 400,
      color: "#6E6E70",
    },
    subtitle1: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#6E6E70",
    },
    body2: {
      fontSize: "0.9rem",
      fontWeight: 400,
      color: "#3A454A",
    },
    body3: {
      fontSize: "0.85rem",
      fontWeight: 500,
      color: "#6E6E70",
    },
    unit: {
      fontSize: "0.7rem",
      fontWeight: 600,
      color: "#6E6E70",
    },
  },
  divider: {
    // Theme Color, or use css color in quote
    background: "#fbe1df",
  },
  otherInfo: {
    appBarData: {
      logoUrl:
        "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/org-icons/optimised-body-mind/logo.png",
      brandingTest: "",
      navBarColor: "",
    },
    assetPath:
      "https://reportsvg.s3.ap-south-1.amazonaws.com/optimized/optimized/svg/",
  },
};

function createThemeSettings() {
  let themeSettings = {
    palette: {
      primary: {
        main: "#0085FF",
      },
      // success: {
      //   main: "#5CE463",
      // },
      // warning: {
      //   main: "#FD9C4D",
      // },
      // error: {
      //   main: "#F36767",
      // },
      // secondary: {
      //   main: "#fbe1df",
      // },
      other: {
        switchView: "#fff",
        profileCardHighlight: "#E8F1FA",
        introScetion: "#E8F1FA",
        bodySummary: "#E5E4E2",
        // cardBackground: "#E3E9F51A",
        bodyFill: "#F6F5FB",
        navBarColor: "",
        slider: {
          abnormal: "#F36767",
          borderline: "#FD9C4D",
          normal: "#5CE463",
          almostNormal: "#99EE9D",
          default: "#00000000",
        },
      },
    },
    components: {
      // MuiDivider: {
      //   styleOverrides: {
      //     root: {
      //       borderColor: "rgb(151, 25, 29)",
      //     },
      //   },
      // },
      // MuiTableCell: {
      //   styleOverrides: {
      //     root: {
      //       borderColor: "rgb(224, 79, 84)",
      //     },
      //   },
      // },
    },
    typography: {
      h2: {
        fontSize: "1.6rem",
        fontWeight: 700,
        lineHeight: 2,
        color: "#3A454A",
      },
      h3: {
        fontSize: "1.6rem",
        fontWeight: 700,
        lineHeight: 2,
        color: "#151A22",
      },
      h4: {
        fontSize: "1.4rem",
        fontWeight: 700,
        lineHeight: 2,
        color: "#3A454A",
      },
      h5: {
        fontSize: "1.0rem",
        fontWeight: 500,
        lineHeight: 1,
        color: "#787A86",
      },

      h6: {
        fontSize: "0.9rem",
        fontWeight: 500,
        color: "#6E6E70",
      },

      carouselText: {
        fontSize: "0.9rem",
        fontWeight: 500,
        color: "#787A86",
      },
      body1: {
        fontSize: "0.9rem",
        fontWeight: 400,
        color: "#6E6E70",
      },
      p: {
        fontSize: "0.9rem",
        fontWeight: 400,
        color: "#6E6E70",
      },
      subtitle1: {
        fontSize: "0.9rem",
        fontWeight: 500,
        color: "#6E6E70",
      },
      body2: {
        fontSize: "0.9rem",
        fontWeight: 400,
        color: "#3A454A",
      },
      body3: {
        fontSize: "0.85rem",
        fontWeight: 500,
        color: "#6E6E70",
      },
      unit: {
        fontSize: "0.7rem",
        fontWeight: 600,
        color: "#6E6E70",
      },
      url: {
        fontSize: "0.9rem",
        fontWeight: 600,
        color: "#0085FF",
      },
    },
    divider: {
      // Theme Color, or use css color in quote
      background: "#fbe1df",
    },
  };

  if (clientSpecificSettings.palette.primary.main)
    themeSettings.palette.primary.main =
      clientSpecificSettings.palette.primary.main;

  if (clientSpecificSettings.palette.other.navBarColor)
    themeSettings.palette.other.navBarColor =
      clientSpecificSettings.palette.other.navBarColor;

  if (clientSpecificSettings.palette.primary.main)
    themeSettings.palette.primary.main =
      clientSpecificSettings.palette.primary.main;

  if (clientSpecificSettings.palette.primary.main)
    themeSettings.palette.primary.main =
      clientSpecificSettings.palette.primary.main;

  return themeSettings;
}

const theme = createTheme(createThemeSettings());

function App() {
  const [id, setId] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);
  const [patientDetails, setPatientDetails] = useState({
    name: "John Doe",
    age: "30",
    location: "New York, NY",
    gender: "Male",
    pid: "ABCTest123",
  });
  const [bodyData, setBodyData] = useState([]);
  const [followUpRecommendation, setFollowUpRecommendation] = useState([]);
  const [assetPath, setAssetPath] = useState(
    // "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svgRed/"
    "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/"
  );
  const [concernAreaData, setConcernAreaData] = useState([]);
  const [recommendationData, setRecommendationData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [autoComplete, setAutoComplete] = useState([]);
  // const [profileData, setProfileData] = useState(input);
  const [carouselData, setCarouselData] = useState([]);
  const [allProfileIntro, setAllProfileIntro] = useState({
    title: "All Profiles",
    text: `💡 Use the search bar to access details of the
              profile you would like to check. Click on the profiles to learn more.`,
  });

  const [profileAnalysisIntro, setProfileAnalysisIntro] = useState({
    title: "Profile View",
    text: [
      `💡 Deep dive into all your test results.Select the relevant cards to learn more about what your results mean.`,
    ],
  });
  const [followUpRecommendationIntro, setFollowUpRecommendationIntro] =
    useState({
      title: "Vitamin Boosts",
      text: [
        `💡 Vitamins are essential for a healthy immune system, normal growth and proper organ function. We have a wide
range of health boosting Vitamin Injections to suit your needs:`,
      ],
    });
  const [panelOverviewIntro, setPanelOverviewIntro] = useState({
    title: "Let’s look at your test profiles",
    text: "💡 Our algorithm suggests that you have a look at these",
  });
  const [recommendationSectionIntro, setRecommendationSectionIntro] = useState({
    title: "Healthy Tips",
    text: "💡 Information you must know and follow to improve your health",
  });
  const [reportIntro, setReportIntro] = useState({
    text: "We're glad to see you take healthy steps. This platform will help you understand and optimize your parameters.",
  });
  // const history2 = useLocation();
  useEffect(() => {
    // console.log("🚀 ~ file: App.js:353 ~ useEffect ~ history", history);
  }, []);
  useEffect(() => {
    async function getUserData() {
      const data = await getInputData(id);
      setProfileData(profileDataHelper(data, theme.palette.other.slider));
      setPatientDetails(patientDetailsDataHelper(data));
      setFollowUpRecommendation(followUpRecommendationDataHelper(data));
      setTimeout(() => {
        setDataLoad(true);
      }, 1500);
    }
    getUserData();
  }, [id]);

  useEffect(() => {
    // console.log("🚀 ~ file: App.js:345 ~ useEffect ~ profileData", profileData);
    setBodyData(bodySummaryHelper(profileData, theme.palette.other.slider));
    setRecommendationData(recommendationDataHelper(profileData));
    setConcernAreaData(concernAreaDataHelper(profileData));
    setCarouselData(CarouselDataHelper(profileData));
    setAutoComplete(autoCompleteHelper(profileData));
    setAssetPath(clientSpecificSettings.otherInfo.assetPath);
  }, [profileData]);

  function SwitchCard() {
    return (
      <ComponentCard
        elevation={0}
        sx={{
          backgroundColor: "#ffffff00",
          marginTop: "-10px",
          // marginX: "",
        }}
      >
        <SwitchView
          bodyData={bodyData}
          patientDetails={patientDetails}
          concernAreaData={concernAreaData}
          recommendationData={recommendationData}
          panelOverviewIntro={panelOverviewIntro}
          recommendationSectionIntro={recommendationSectionIntro}
          reportIntro={reportIntro}
          profileData={profileData}
          carouselData={carouselData}
          allProfileIntro={allProfileIntro}
          profileAnalysisIntro={profileAnalysisIntro}
          assetPath={assetPath}
          autoComplete={autoComplete}
          followUpRecommendation={followUpRecommendation}
          followUpRecommendationIntro={followUpRecommendationIntro}
          setId={setId}
        />
        <CenteredBox sx={{ height: 30, paddingY: 1 }}>
          <Typography center>Powered By &copy;&nbsp; </Typography>
          <img src={niroggyanLogo} height="100%" alt="logo" />
        </CenteredBox>
      </ComponentCard>
    );
  }
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <LogoAppBar
          // autoComplete={autoComplete}
          appBarData={clientSpecificSettings.otherInfo.appBarData}
        />
        <Container
          disableGutters={true}
          maxWidth="sm"
          sx={{ backgroundColor: theme.palette.other.cardBackground }}
        >
          <BackdropLoader currentState={dataLoad} />
          <Introsection patientDetails={patientDetails} assetPath={assetPath} />
          <Routes>
            <Route path="/overview" element={<SwitchCard />} />
            <Route path="/smartview/*" element={<SwitchCard />} />
            {/* <Route path="/smartview/:id" element={<SmartView />} /> */}
            <Route path="*" element={<Navigate to="/overview" replace />} />
          </Routes>
        </Container>
      </ThemeProvider>
    </Box>
  );
}

export default App;
