import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import { LeftBox } from "../common/BoxAbstraction";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import image from "../common/card-bg.jpeg";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
// import MyLocationIcon from "@mui/icons-material/MyLocation";
// import { ReactComponent as MyLocationIcon } from "../assets/location.svg";
// import MaleIcon from "@mui/icons-material/Male";
// import { ReactComponent as MaleIcon } from "../assets/male.svg";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// import { ReactComponent as PersonOutlineIcon } from "../assets/years.svg";
// import FemaleIcon from "@mui/icons-material/Female";
// import Button from "@mui/material/Button";
import { common } from "@mui/material/colors";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";
import { LeftItems } from "../common/PaperAbstraction";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

const selectionStyle = {
  // backgroundImage: `url(${image})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: 2,
};

const boxStyle = {
  padding: 0.5,
  display: "flex",
  // color: common.white,
  alignItems: "center",
  justifyContent: "center",
};

// const theme = createTheme({
//   typography: {
//     allVariants: {
//       color: common.white,
//     },
//     h3: {
//       fontSize: "1.5rem",
//       fontWeight: 700,
//       lineHeight: 1,
//     },
//     h4: {
//       fontSize: "0.9rem",
//       fontWeight: 400,
//     },
//     h6: {
//       fontSize: "0.9rem",
//       fontWeight: 500,
//     },
//     body1: {
//       fontSize: "1rem",
//       fontWeight: 400,
//       marginLeft: 2,
//     },
//   },
// });

const sampleProps = {
  name: "John Doe",
  age: "30 Years",
  location: "New York, NY",
};

const localProps = {
  MyLocationIcon: "building.png",
  MaleIcon: "male.png",
  FemaleIcon: "human.png",
  PersonOutlineIcon: "human.png",
};
export default function Introsection(props) {
  // props.patientDeatils Details on the patient
  const globalTheme = useTheme();
  const theme = globalTheme;

  const imgSrc =
    props.patientDetails.gender == "Male"
      ? localProps.MaleIcon
      : localProps.FemaleIcon;

  return (
    <Paper
      square={true}
      elevation={0}
      sx={{
        ...selectionStyle,
        backgroundColor:
          globalTheme.palette.other.introScetion ||
          globalTheme.palette.primary.light,
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <LeftItems elevation={0}>
              <Typography variant="h5">Welcome&nbsp;</Typography>
              <Typography variant="h3">{props.patientDetails.name}</Typography>
            </LeftItems>
            <LeftItems elevation={0} sx={{ marginY: 1 }}>
              <Box sx={boxStyle}>
                <PermIdentityOutlinedIcon color="primary" />
                <Typography variant="h5">
                  {props.patientDetails.gender}
                </Typography>
                &nbsp;|&nbsp;
                <Typography variant="h5">
                  {props.patientDetails.age}
                  {/* {props.patientDetails.pid}&nbsp;|&nbsp;
                  {props.patientDetails.pid} */}
                </Typography>
              </Box>
              |
              <Box sx={boxStyle}>
                <Typography
                  variant="h5"
                  color={globalTheme.palette.primary.main}
                >
                  PID:{" "}
                </Typography>
                <Typography variant="h5">{props.patientDetails.pid}</Typography>
              </Box>
              |
              <Box sx={boxStyle}>
                <TagOutlinedIcon color="primary" />
                <Typography variant="h5">
                  {props.patientDetails.sampleId}
                </Typography>
              </Box>
            </LeftItems>
          </Grid>
          {/* <Grid item xs={12} marginTop={4}>
            <LeftBox>
              <Typography variant="h6">Download</Typography>
              <Button variant="contained" size="small">
                Smart Report
              </Button>
              <Button variant="contained" size="small">
                Lab Report
              </Button>
            </LeftBox>
          </Grid> */}
        </Grid>
      </ThemeProvider>
    </Paper>
  );
}
