import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

function createData(type, low, high, resultValue, indicatorColorPallet) {
  let signalColor = indicatorColorPallet.default;

  const lowFloat = parseFloat(low);
  const highFloat = parseFloat(high);
  const result = parseFloat(resultValue);

  if (low == high) {
    signalColor = indicatorColorPallet.default;
  } else {
    // console.log(
    //   "🚀 ~ file: RangeTable.js:25 ~ createData ~ resultValue",
    //   resultValue,
    //   low,
    //   high
    // );
    if (result < lowFloat) signalColor = indicatorColorPallet.abnormal;
    else if (result < highFloat) signalColor = indicatorColorPallet.normal;
    else signalColor = indicatorColorPallet.abnormal;
  }

  return { type, low, high, signalColor };
}

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

function CreateRow(props) {
  let indicatorColor = props?.signalColor;
  // if (props.status === "normal") indicatorColor = "green";
  // else if (props.status == "finalCritical") indicatorColor = "red";
  // else if (props.status == "finalCritial") indicatorColor = "red";
  // else if (props.status == "oneFromNormal") indicatorColor = "orange";
  // else if (props.status == "twoFromNormal") indicatorColor = "orange";
  // else indicatorColor = "#00000000";
  return (
    <TableRow
      key={props.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        height: "35px",
      }}
    >
      {/* <TableCell align="center" padding="none" margin="none">
      </TableCell> */}
      <TableCell component="th" scope="row" padding="none">
        <Typography variant="body3">{props.type}</Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography variant="body3">{props.low}</Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography variant="unit">{props.high}</Typography>
      </TableCell>
      {/* <TableCell align="center" padding="none" margin="none">
        <CircleIcon
          sx={{
            height: 15,
            width: 15,
            padding: 0,
            margin: 0,
            color: { color: indicatorColor },
          }}
          padding={0}
          margin={0}
        />
      </TableCell> */}
    </TableRow>
  );
}

export default function RangeTable(props) {
  // console.log("🚀 ~ file: RangeTable.js:71 ~ RangeTable ~ props", props);
  const theme = useTheme();

  const rows = props?.ranges?.others?.map((item) =>
    createData(
      item?.rangeHeader,
      item?.range?.minParameterValue,
      item?.range?.maxParameterValue,
      props?.resultValue,
      theme.palette.other.slider
    )
  );

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ bgcolor: "inherit", width: "70%", marginX: "auto" }}
      >
        <Table
          sx={{ minWidth: 200, bgcolor: "inherit" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {/* <TableCell>&nbsp;</TableCell> */}
              <TableCell padding="none">
                <Typography variant="unit">Type</Typography>
              </TableCell>
              <TableCell align="left" padding="none">
                <Typography variant="unit">Low Range</Typography>
              </TableCell>
              <TableCell align="left" padding="none">
                <Typography variant="unit">High Range</Typography>
              </TableCell>
              {/* <TableCell align="center" padding="none" margin="none">
                <Typography variant="unit">Indicator</Typography>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <CreateRow key={index} {...row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
