import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { CenterItems, CoveringCard } from "../common/PaperAbstraction";
import { SectionTitleWithIntro } from "../common/BoxAbstraction";
import checkbox from "../assets/checkbox.svg";
import { ReactComponent as Checkbox } from "../assets/checkbox.svg";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";
import { Button  } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";

const baseURL = "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/";

export function RecommendationItem(props) {
  let img = `${props.assetPath}${props.image}`;
  if (!props.image) img = checkbox;

  return (
    <>
      <Grid item xs={2} md={1} alignSelf="center">
        <CenterItems elevation={0}>
          <Box>
            {/* <SvgIcon sx={{ height: "30px", width: "0px" }}>
            <Checkbox />
          </SvgIcon> */}
            <Avatar
              src={`${img}`}
              alt={checkbox}
              variant="square"
              sx={{ height: 35, width: 35 }}
            />
          </Box>
        </CenterItems>
      </Grid>
      <Grid item xs={10} md={11}>
        <Box>
          <Typography variant="p">{props.text}</Typography>
        </Box>
      </Grid>
    </>
  );
}

export default function RecommendationSection(props) {
  const theme = useTheme();

   
  const handleChange = (event, newValue) => {
    let newTab = 2;
    if (newValue in props.tabNameToIndex) props.navigate(`/${props.tabNameToIndex[newValue]}`);
    if (newValue in props.indexToTabName) {
      newTab = props.indexToTabName[newValue];
      props.navigate(`/${newValue}`);
    }
    props.setSelectedTab(newTab);
  };
 
  if(props?.recommendationData?.length==0||!props?.recommendationData)
  return <></>;
  return (
    <Paper
      square={true}
      elevation={0}
      sx={{ bgcolor: theme.palette.other.cardBackground }}
    >
      <SectionTitleWithIntro {...props.recommendationSectionIntro} />
      <Box>
        <CoveringCard elevation={0}>
          <Grid container spacing={2}>
            {props.recommendationData.map((item, index) => (
              <RecommendationItem key={`${index}`} {...item} />
            ))}
          </Grid>
        </CoveringCard>
      </Box>
    </Paper>
  );
}
