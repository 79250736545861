import { styled } from "@mui/material/styles";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

export const CenteredBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const LeftBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(1),
}));

export function SectionTitle(props) {
  const extraText = props.extraText || "";

  return (
    <Box paddingX={2} paddingTop={1}>
      <Typography variant="h6" {...props}>
        <Typography variant="h6">{props.title}</Typography>
        <Typography variant="p">{extraText}</Typography>
      </Typography>
    </Box>
  );
}
export function SectionTitleWithIntro(props) {
  const text = props.text || "";
  return (
    <Box paddingX={2} paddingTop={2} paddingBot={1} {...props}>
      <Typography variant="h3">{props.title}</Typography>
      <Typography variant="subtitle1">{text}</Typography>
    </Box>
  );
}

export function SectionSubTitleWithIntro(props) {
  const text = props.text || "";
  return (
    <Box marginBottom={2} paddingTop={2} paddingBot={5} {...props}>
      <Typography variant="h4">{props.title}</Typography>
      <Typography variant="subtitle1">{text}</Typography>
    </Box>
  );
}


export function ParagraphText(props) {
  const text = props.text || "";
  return (
    <Box  {...props}>
      <Typography variant="p">{text}</Typography>
    </Box>
  );
}