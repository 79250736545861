const allIconsLinks = {
  "Electrolyte Profile": {
    viewboxValue: "",
    circleX: "199",
    circleY: "130",
    x2: "66.5",
    y2: "101.5",
    imageX: "49",
    imageY: "84",
    textX: "29",
    textY: "141",
    r: "3",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/electrolyte-profile.png",
  },
  "Anemia Studies": {
    viewboxValue: "",
    circleX: "291",
    circleY: "314",
    x2: "360.5",
    y2: "366.5",
    imageX: "343",
    imageY: "349",
    textX: "322",
    textY: "404",
    r: "3",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/anemia-studies.png",
  },
  "Thyroid Profile": {
    viewboxValue: "",
    circleX: "220",
    circleY: "115",
    x2: "360.5",
    y2: "101.5",
    imageX: "343",
    imageY: "84",
    textX: "325",
    textY: "141",
    r: "3",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/ThyroidProfile-icon.png",
  },
  "Blood Counts": {
    viewboxValue: "",
    circleX: "179",
    circleY: "166",
    x2: "66.5",
    y2: "189.5",
    imageX: "49",
    imageY: "172",
    textX: "34",
    textY: "227",
    r: "3",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/blood-test.png",
  },
  "Liver Profile": {
    viewboxValue: "",
    circleX: "210",
    circleY: "214",
    x2: "360.5",
    y2: "189.5",
    imageX: "343",
    imageY: "172",
    textX: "333",
    textY: "227",
    r: "3",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/LiverProfile-icon.png",
  },
  Urinalysis: {
    viewboxValue: "",
    circleX: "182",
    circleY: "300",
    x2: "66.5",
    y2: "366.5",
    imageX: "49",
    imageY: "349",
    textX: "42",
    textY: "404",
    r: "3",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/urinalysis.png",
  },
  Arthritis: {
    viewboxValue: "",
    circleX: "195",
    circleY: "430",
    x2: "66.5",
    y2: "543.5",
    imageX: "49",
    imageY: "526",
    textX: "47",
    textY: "583",
    r: "3",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/arthritis-screening.png",
  },
  "Kidney Profile": {
    viewboxValue: "",
    circleX: "220",
    circleY: "300",
    x2: "360.5",
    y2: "543.5",
    imageX: "343",
    imageY: "526",
    textX: "327",
    textY: "583",
    r: "3",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/KidneyProfile-icon.png",
  },
  "Lipid Profile": {
    viewboxValue: "",
    circleX: "76.5",
    circleY: "307.5",
    x2: "66.5",
    y2: "278.5",
    imageX: "49",
    imageY: "261",
    textX: "38",
    textY: "317",
    r: "0",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/LipidProfile-icon.png",
  },
  // "Vitamin D" : {
  //     x: "330",
  //     y: "261",
  //     link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/vitamin-d.svg"
  // },
  Vitamin: {
    viewboxValue: "",
    circleX: "350.5",
    circleY: "427.5",
    x2: "360.5",
    y2: "455.5",
    imageX: "343",
    imageY: "438",
    textX: "341",
    textY: "493",
    r: "0",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/VitaminProfile-icon.png",
  },
  "Diabetes Monitoring": {
    viewboxValue: "",
    circleX: "86.5",
    circleY: "477.5",
    x2: "66.5",
    y2: "455.5",
    imageX: "49",
    imageY: "438",
    textX: "20",
    textY: "493",
    r: "0",
    link: "https://niroggyan.s3.ap-south-1.amazonaws.com/smart-app/svg/diabetes-profile.png",
  },
};

export default allIconsLinks;