import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {
  CenterItems,
  CoveringCard,
  LeftItems,
  RightItems,
} from "../common/PaperAbstraction";
import { HashLink, NavHashLink } from "react-router-hash-link";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import parse from "html-react-parser";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import { Paper } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// LinearProgressWithLabel.propTypes = {
//   /**
//    * The value of the progress indicator for the determinate and buffer variants.
//    * Value between 0 and 100.
//    */
//   value: PropTypes.number.isRequired,
// };

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.mode === "light" ? "#0F9D58" : "#0F9D58",
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     elevation: 0,
//     backgroundColor: theme.palette.mode === "light" ? "#DB4437" : "#DB4437",
//   },
// }));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.other.slider.abnormal
        : "#DB4437",
  },
  [`& .${linearProgressClasses.bar}`]: {
    elevation: 0,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.other.slider.normal
        : "#0F9D58",
  },
}));
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 2 }}>
        <BorderLinearProgress
          variant="determinate"
          {...props}
          sx={{
            borderRadius: 4,
            height: 8,
            bar1ColorPrimary: "#000",
            barColorSecondary: "#000",
          }}
        />
      </Box>
      <Box sx={{ minWidth: 150 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.barText}`}</Typography>
      </Box>
    </Box>
  );
}

// const sampleProp = {
//   id: 1,
//   heading: "Diabetes",
//   subHeading:
//     "Diabetes is a condition in which the blood glucose levels are too high.",
//   avatar:
//     "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
//   progressValue: 50,
//   progressBarText: "5/10",
//   subText: `Diabetes is a serious disease that can all of your body.Understanding it deeply can help you follow your treatment plan and stay as healthy as possible.`,
// };
export default function ProfilePanelOverviewCards(props) {
  // console.log(
  //   "🚀 ~ file: UpsellCard.js:96 ~ ProfilePanelOverviewCards ~ props",
  //   props
  // );
  return (
    <Paper elevation={0} variant="outlined">
      <Grid
        padding={1}
        container
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={10}>
          <LeftItems elevation={0} id={props.slug2}>
            <Typography variant="h4">{props.name}</Typography>
          </LeftItems>
          <Box>
            <Typography variant="p">{parse(props.description)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <RightItems elevation={0}>
            <a href={props.url} target="_blank">
              <Box
                sx={{ position: "relative", marginRight: 5, marginBottom: 3 }}
              >
                {/* <Brightness1OutlinedIcon
                  sx={{ position: "absolute", fontSize: "30px" }}
                  color="primary"
                  variant="light"
                />
                <KeyboardArrowRightRoundedIcon
                  sx={{ position: "absolute", fontSize: "30px" }}
                  color="primary"
                  variant="light"
                /> */}
                <OpenInNewIcon 
                 sx={{ position: "absolute", fontSize: "30px" }}
                 color="primary"
                 variant="light"/>
              </Box>
            </a>
          </RightItems>
        </Grid>
      </Grid>
    </Paper>
  );
}
