import * as React from "react";
import Box from "@mui/material/Box";
import Slider, { sliderClasses } from "@mui/material/Slider";
// import { makeStyles, withStyles } from "@material-ui/core/styles";
import { makeStyles, withStyles } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CenterItems } from "../common/PaperAbstraction";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

// const useStyles = makeStyles({
//   root: {
//     width: 200,
//   },
// });

// const CustomSlider = withStyles({
//   root: {
//     height: 8,
//     "&$vertical": {
//       width: 8,
//     },
//   },
//   rail: {
//     height: 8,
//     borderRadius: 4,
//     backgroundImage:
//       "linear-gradient(to right, red, red 50%, green 50%, green)",
//   },
//   track: {
//     height: 8,
//     borderRadius: 4,
//     backgroundImage:
//       "linear-gradient(to right, red, red 50%, green 50%, green)",
//   },
// })(Slider);

const marks = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 90,
    label: "90",
  },
];

function valuetext(value) {
  return `${value}`;
}

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

// const BorderLinearProgress = styled(sliderClasses)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${sliderClasses.disabled}`]: {
//     pointerEvents: "none",
//     cursor: "default",
//   },
//   [`& .${sliderClasses.rail}`]: {
//     background-image:linear-gradient(to right, red, red 50%, green 50%, green);

//   },
// }));

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

function linerInterpolation(x1, x2, y1, y2, scalingValue) {
  const xResult = y1 + ((y2 - y1) / (x2 - x1)) * scalingValue;
  return parseInt(xResult);
}

function createMark3(lowValue, highValue, resultValue) {
  const marks = [];
  marks.push({
    value: 25,
    label: lowValue,
  });
  marks.push({
    value: 75,
    label: highValue,
  });
  const resultObj = {
    label: resultValue,
    value: 0,
  };
  if (resultValue < lowValue) {
    resultObj.value = linerInterpolation(0, lowValue, 0, 25, resultValue);
  } else if (resultValue < highValue) {
    resultObj.value = linerInterpolation(
      lowValue,
      highValue,
      25,
      75,
      resultValue - lowValue
    );
  } else
    resultObj.value = linerInterpolation(
      highValue,
      2 * highValue,
      75,
      100,
      resultValue - highValue
    );

  if (resultObj.value < 5) resultObj.value = 5;
  if (resultObj.value > 95) resultObj.value = 95;

  return [marks, resultObj.value];
}

function createMark2(cutoff, resultValue) {
  const marks = [];
  marks.push({
    value: 50,
    label: cutoff,
  });
  // marks.push({
  //   value: 75,
  //   label: highValue,
  // });
  const resultObj = {
    label: resultValue,
    value: 0,
  };
  if (resultValue < cutoff) {
    resultObj.value = linerInterpolation(0, cutoff, 0, 50, resultValue);
  } else
    resultObj.value = linerInterpolation(
      cutoff,
      2 * cutoff,
      50,
      100,
      resultValue - cutoff
    );

  if (resultObj.value < 5) resultObj.value = 5;
  if (resultObj.value > 95) resultObj.value = 95;

  return [marks, resultObj.value];
}
function createMark4(lowValue, borderline, highValue, resultValue) {
  const marks = [];
  marks.push({
    value: 25,
    label: lowValue,
  });
  marks.push({
    value: 50,
    label: borderline,
  });
  marks.push({
    value: 75,
    label: highValue,
  });
  const resultObj = {
    label: resultValue,
    value: 0,
  };
  if (resultValue < lowValue) {
    resultObj.value = linerInterpolation(0, lowValue, 0, 25, resultValue);
  } else if (resultValue < borderline) {
    resultObj.value = linerInterpolation(
      lowValue,
      borderline,
      25,
      50,
      resultValue - lowValue
    );
  } else if (resultValue < highValue) {
    resultObj.value = linerInterpolation(
      borderline,
      highValue,
      50,
      75,
      resultValue - borderline
    );
  } else
    resultObj.value = linerInterpolation(
      highValue,
      2 * highValue,
      75,
      100,
      resultValue - highValue
    );

  if (resultObj.value < 5) resultObj.value = 5;
  if (resultObj.value > 95) resultObj.value = 95;

  return [marks, resultObj.value];
}
let sliderBackgroundImage = {
  LNH: "linear-gradient(to right, red, red 25%, green 25%, green 75%,red 75%, red) !important",
  LN: "linear-gradient(to right, red, red 50%, green 50%, green 100%) !important",
  NH: "linear-gradient(to right, green, green 50%, red 50%, red 100%) !important",
  NHHN: "linear-gradient(to right, green, green 25%, orange 25%, orange 75%,red 75%, red) !important",
  LLNH: "linear-gradient(to right, red, red 25%, orange 25%, orange 50%, lightgreen 50%,lightgreen 75%,green 75%, green) !important",
};

export default function DiscreteSliderValues(props) {
  const theme = useTheme();
  if (!(props?.sliderType in sliderBackgroundImage)) return <></>;

  sliderBackgroundImage = {
    LNH: `linear-gradient(to right, ${theme.palette.other.slider.abnormal}, ${theme.palette.other.slider.abnormal} 25%,${theme.palette.other.slider.normal} 25%,${theme.palette.other.slider.normal} 75%,${theme.palette.other.slider.abnormal} 75%, ${theme.palette.other.slider.abnormal}) !important`,
    LN: `linear-gradient(to right, ${theme.palette.other.slider.abnormal}, ${theme.palette.other.slider.abnormal} 50%,${theme.palette.other.slider.normal} 50%,${theme.palette.other.slider.normal} 100%) !important`,
    NH: `linear-gradient(to right,${theme.palette.other.slider.normal},${theme.palette.other.slider.normal} 50%, ${theme.palette.other.slider.abnormal} 50%, ${theme.palette.other.slider.abnormal} 100%) !important`,
    NHHN: `linear-gradient(to right,${theme.palette.other.slider.normal},${theme.palette.other.slider.normal} 25%, ${theme.palette.other.slider.borderline} 25%, ${theme.palette.other.slider.borderline} 75%,${theme.palette.other.slider.abnormal} 75%, ${theme.palette.other.slider.abnormal}) !important`,
    LLNH: `linear-gradient(to right, ${theme.palette.other.slider.abnormal}, ${theme.palette.other.slider.abnormal} 25%, ${theme.palette.other.slider.borderline} 25%, ${theme.palette.other.slider.borderline} 50%, ${theme.palette.other.slider.almostNormal} 50%,${theme.palette.other.slider.almostNormal} 75%,${theme.palette.other.slider.normal} 75%,${theme.palette.other.slider.normal}) !important`,
  };

  let backgroundImageCss = "";

  let marks, defaultValue;

  if (props.sliderType == "LNH") {
    backgroundImageCss = sliderBackgroundImage.LNH;
    [marks, defaultValue] = createMark3(
      props.lowThreshold,
      props.highThreshold,
      props.resultValue
    );
  } else if (props.sliderType == "LN") {
    backgroundImageCss = sliderBackgroundImage.LN;
    [marks, defaultValue] = createMark2(props.lowThreshold, props.resultValue);
  } else if (props.sliderType == "NH") {
    backgroundImageCss = sliderBackgroundImage.NH;
    [marks, defaultValue] = createMark2(props.highThreshold, props.resultValue);
  } else if (props.sliderType == "NHHN") {
    backgroundImageCss = sliderBackgroundImage.NHHN;
    [marks, defaultValue] = createMark3(
      props.boderline,
      props.highThreshold,
      props.resultValue
    );
  } else if (props.sliderType == "LLNH") {
    backgroundImageCss = sliderBackgroundImage.LLNH;
    [marks, defaultValue] = createMark4(
      props.lowThreshold,
      props.boderline,
      props.highThreshold,
      props.resultValue
    );
  }

  const color = props.signalColor;
  return (
    <Grid item xs={12}>
      <CenterItems elevation={0}>
        <Box sx={{ width: 300 }}>
          <PrettoSlider
            aria-label="Restricted values"
            defaultValue={defaultValue}
            valueLabelFormat={valueLabelFormat}
            getAriaValueText={valuetext}
            marks={marks}
            step={null}
            valueLabelDisplay="auto"
            // size="medium"
            // disableSwap={true}
            disabled={true}
            // color="secondary"
            track={false}
            // color
            // sx={{ backgroundRail: "red" }}
            // onChange={(event, value) => return)
            sx={{
              "& .Mui-disabled": {
                color: "fbfbfb",
                border: `2px solid ${color}`,
                opacity: "100%",

                // backgroundImage:
                //   "linear-gradient(to right, red, red 50%, green 50%, green)",
              },
              "& .MuiSlider-rail": {
                height: 8,
                backgroundImage: backgroundImageCss,
                // opacity:"100% !"
                opacity: "100% !important",
              },
              "& .MuiSlider-mark": {
                color: "white",
                position: "absolute",
                top: "38%",
                height: "26%",
                width: "3px",
                opacity: "1",
                transform: "translateX(-50%)",
              },
            }}
          />
        </Box>
      </CenterItems>
    </Grid>
  );
}
