import React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { HashLink } from "react-router-hash-link";
import niroggyanLogo from "../assets/niroggyanWhite.png";
import { useTheme } from "@mui/material/styles";
// import { Link } from 'react-router-dom';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function handleSearch(event) {
  // console.log(
  //   "🚀 ~ file: LogoAppBar.js ~ line 58 ~ handleSearch ~ event",
  //   event
  // );
  if (event.key === "Enter") {
    // Prevent's default 'Enter' behavior.
    // event.defaultMuiPrevented = true;
    // re;
    // your handler code
  }
}

export default function SearchAppBar(props) {
  const theme = useTheme();
  // console.log("🚀 ~ file: LogoAppBar.js:92 ~ SearchAppBar ~ theme", theme);
  const defaultProps = {
    options: props.autoComplete,
    getOptionLabel: (option) => option.name,
  };
  let logo = niroggyanLogo;
  if (props.appBarData.logoUrl) logo = props.appBarData.logoUrl;

  let navBarColor = "primary";

  if (theme.palette.other.navBarColor) {
    navBarColor = theme.palette.other.navBarColor;
  }
  // console.log(
  //   "🚀 ~ file: LogoAppBar.js:103 ~ SearchAppBar ~ theme.palette.other.navBarColor",
  //   theme.palette.other.navBarColor
  // );

  // console.log(
  //   "🚀 ~ file: LogoAppBar.js:101 ~ SearchAppBar ~ nabBarColor",
  //   navBarColor
  // );
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: navBarColor }}>
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Box sx={{ height: 60, paddingY: 1 }}>
            <img src={logo} height="100%" alt="logo" />
          </Box>
          <Typography
            variant="h2"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "block", sm: "block" },
              paddingX: 2,
            }}
          ></Typography>
          {/* <Autocomplete
            sx={{ width: 250 }}
            {...defaultProps}
            // options={countries}
            // getOptionLabel={(option) => option.label}
            autoHighlight
            renderOption={(props, option) => (
              <HashLink to={`/smartview#${option.slug}`} smooth>
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.name}
                </Box>
              </HashLink>
            )}
            renderInput={(params) => (
              <TextField
                sx={
                  {
                    // bgcolor: "#ffffff",
                  }
                }
                {...params}
                label="Search"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          /> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
