import React from "react";
import allIconsLinks from "../allIconsLinks";
import '../css/profilesDatabase.css'
import { HashLink } from 'react-router-hash-link';

function DashedLineAndCirclePinter({
  radius,
  circleX,
  circleY,
  color,
  x2,
  y2,
}) {
  if (radius === 0 || radius === "0") return <></>;
  return (
    <>
      <circle r={radius} cx={circleX} cy={circleY} fill={color} />
      <line
        x1={circleX}
        y1={circleY}
        x2={x2}
        y2={y2}
        stroke={color}
        stroke-dasharray="4"
      />
    </>
  );
}

const GetProfileImage = ({ profileName, color,assetPath,slug,image }) => {
  if(!allIconsLinks?.[profileName]) return <></>


  let viewboxValue = allIconsLinks?.[profileName].viewboxValue;
  let circleX = allIconsLinks?.[profileName].circleX;
  let circleY = allIconsLinks?.[profileName].circleY;
  let x2 = allIconsLinks?.[profileName].x2;
  let y2 = allIconsLinks?.[profileName].y2;
  let imageX = allIconsLinks?.[profileName].imageX;
  let imageY = allIconsLinks?.[profileName].imageY;
  let imageLink = allIconsLinks?.[profileName].link;
  let radius = allIconsLinks?.[profileName].r;
  let textX = allIconsLinks?.[profileName].textX;
  let textY = allIconsLinks?.[profileName].textY;

  // const DashedLineAndCirclePinter = dashedLineAndCirclePinter(
  //   radius,
  //   circleX,
  //   circleY,
  //   color,
  //   x2,
  //   y2
  // );


  return (
    <React.Fragment>
      <HashLink to={`#${slug}`} smooth className="body-summary">

      <svg viewBox={viewboxValue} xmlns="http://www.w3.org/2000/svg">
        <DashedLineAndCirclePinter
          radius={radius}
          circleX={circleX}
          circleY={circleY}
          color={color}
          x2={x2}
          y2={y2}
          />
        <circle
          cx={x2}
          cy={y2}
          r="25"
          fill="white"
          stroke={color}
          stroke-dasharray="4"
          />
        <image
          x={imageX}
          y={imageY}
          href={`${assetPath}${image}`}
          height="35px"
          width="35px"
          />
        <text
          x={textX}
          y={textY}
          fill="black"
          font-size="10px"
          font-weight="bold"
          >
          {profileName}
        </text>
      </svg>
          </HashLink>
    </React.Fragment>
  );
};

export default GetProfileImage;