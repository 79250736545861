import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import {
  CenterItems,
  CoveringCard,
  LeftItems,
  RightItems,
} from "../common/PaperAbstraction";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Divider } from "@mui/material";

// LinearProgressWithLabel.propTypes = {
//   /**
//    * The value of the progress indicator for the determinate and buffer variants.
//    * Value between 0 and 100.
//    */
//   value: PropTypes.number.isRequired,
// };

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#0F9D58" : "#0F9D58",
  },
  [`& .${linearProgressClasses.bar}`]: {
    elevation: 0,
    backgroundColor: theme.palette.mode === "light" ? "#DB4437" : "#DB4437",
  },
}));
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 2 }}>
        <BorderLinearProgress
          variant="determinate"
          {...props}
          sx={{
            borderRadius: 4,
            height: 8,
            bar1ColorPrimary: "#000",
            barColorSecondary: "#000",
          }}
        />
      </Box>
      <Box sx={{ minWidth: 150 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.barText}`}</Typography>
      </Box>
    </Box>
  );
}

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ArcElement);

// export const data = {
//   // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//   datasets: [
//     {
//       label: "# of Votes",
//       data: [12, 19, 3, 5, 2, 3],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(54, 162, 235, 0.2)",
//         "rgba(255, 206, 86, 0.2)",
//         "rgba(75, 192, 192, 0.2)",
//         "rgba(153, 102, 255, 0.2)",
//         "rgba(255, 159, 64, 0.2)",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(75, 192, 192, 1)",
//         "rgba(153, 102, 255, 1)",
//         "rgba(255, 159, 64, 1)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

export default function ProfilePanelOverviewCards(props) {
  return (
    <>
      <Grid
        container
        spacing={2}
        marginBottom={2}
        marginTop={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={9}>
          <LeftItems elevation={0}>
            <Box sx={{ maxHeight: 40, maxWidth: 40, marginRight: 2 }}>
              <img
                src={`${props.assetPath}${props.avatar}`}
                alt="temp"
                variant="square"
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </Box>

            <Typography variant="h4">{props.heading}</Typography>
          </LeftItems>
        </Grid>
        <Grid item xs={3}>
          <RightItems elevation={0}>
            <Box marginTop={-1} height={40} width={40}>
              {/* <LinearProgressWithLabel
              value={props.progressValue}
              barText={`${props.progressBarText}`}
            /> */}

              <Doughnut data={props.pieChartData} showToolTip={false} />
              {/* <LinearProgressWithLabel value={30} barText={"3/10"} /> */}
            </Box>
          </RightItems>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Typography variant="p">{props.subText}</Typography>
          </Box>
        </Grid>
      </Grid>
      {!props.lastIndex&&<Divider style={{ width: "97%" }} variant="middle" />}
    </>
  );
}
