import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
// import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OverView from "../pages/OverView";
import SmartView from "../pages/SmartView";
import {  useNavigate } from "react-router-dom";
// import SvgIcon from "@mui/material/SvgIcon";
// import { ReactComponent as EyesOpen } from "../assets/eyesOpen.svg";
// import { ReactComponent as EyesClosed } from "../assets/eyesClosed.svg";
import { useLocation } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Paper } from "@mui/material";
import { HashLink } from "react-router-hash-link";
// function OpenEyes() {
//   return (
//     <SvgIcon>
//       <EyesOpen />
//     </SvgIcon>
//   );
// }

// function ClosedEyes() {
//   return (
//     <SvgIcon>
//       <ClosedEyes />
//     </SvgIcon>
//   );
// }

// function isSelectedEyes(flag) {
//   if (flag) return <OpenEyes />;
//   return <ClosedEyes />;
// }

export default function SwitchView(props) {
  const theme = useTheme();
  const history = useLocation();
  // console.log("🚀 ~ file: SwitchView.js:44 ~ SwitchView ~ history", history);
  let id = history?.pathname?.split("/")[1];
  let hash = history?.hash || "";
  const [switchHash, setSwitchHash] = useState(hash);
  // console.log("🚀 ~ file: SwitchView.js:47 ~ SwitchView ~ hash", hash)
  // let obj = useParams();

  const tabNameToIndex = {
    0: "overview",
    1: "disabled",
    2: "smartview",
  };

  const indexToTabName = {
    overview: 0,
    disabled: 1,
    smartview: 2,
  };

  const [selectedTab, setSelectedTab] = useState(indexToTabName[id]);

  let navigate = useNavigate();

  const handleChange = (event, newValue) => {
    let newTab = newValue;
    if (newValue in tabNameToIndex) navigate(`/${tabNameToIndex[newValue]}`);
    if (newValue in indexToTabName) {
      newTab = indexToTabName[newValue];
      navigate(`/${newValue}${hash}`);
    }
    setSelectedTab(newTab);
  };

  const handleChangeIndex = (index) => {
    setSelectedTab(index);
  };

  function OverViewTabPanel(props) {
    return (
      <OverView
        bodyData={props.bodyData}
        patientDetails={props.patientDetails}
        concernAreaData={props.concernAreaData}
        recommendationData={props.recommendationData}
        assetPath={props.assetPath}
        followUpRecommendation={props.followUpRecommendation}
        followUpRecommendationIntro={props.followUpRecommendationIntro}
        panelOverviewIntro={props.panelOverviewIntro}
        recommendationSectionIntro={props.recommendationSectionIntro}
        reportIntro={props.reportIntro}
        setSelectedTab={setSelectedTab}
        navigate={navigate}
        indexToTabName={indexToTabName}
        tabNameToIndex={tabNameToIndex}
        handleChange={handleChange}
        hash={hash}
      />
    );
  }

  function SmartViewTabPanel(props) {
    return (
      <SmartView
        profileData={props.profileData}
        carouselData={props.carouselData}
        allProfileIntro={props.allProfileIntro}
        assetPath={props.assetPath}
        profileAnalysisIntro={props.profileAnalysisIntro}
        autoComplete={props.autoComplete}
        hash={switchHash}
      />
    );
  }

  useEffect(() => {
    if (id in indexToTabName) {
      navigate(`/${id}${hash}`);
      setSelectedTab(indexToTabName[id]);
    }
  }, [id]);

  useEffect(() => {
    if (history.search) {
      const id = history?.search?.split("?")[1];
      // console.log("🚀 ~ file: SwitchView.js:125 ~ useEffect ~ id", id);
      props.setId(id);
      //sample structure
      // 
      localStorage.clear();
      const test = {
        id:id,
        date:Date.now()
      }
      localStorage.setItem("data", JSON.stringify(test));
    } else {
      try {
        const rawData = localStorage.getItem("data")
        const data = JSON.parse(rawData);
        const timeDiff = Date.now()-data.date
        if(timeDiff<604800000) props.setId(data.id)
        // console.log("🚀 ~ file: SwitchView.js:138 ~ useEffect ~ data:", data)
      } catch (error) {
        console.log( error)
        
      }
    }
  }, []);

  return (
    <Box
      sx={{
        borderRadius: "20px",
        background: theme.palette.other.switchView,
      }}
    >
      <Paper
        // elevation={0}
        sx={{
          zIndex: "999",
          padding: "10px 12px",
          textAlign: "center",
          borderRadius: "50%",
          fontWeight: "700",
          textDecoration: "none",
          textTransform: "uppercase",
          position: "fixed",
          bottom: "80px",
          right: "15px",
        }}
      >
        <HashLink smooth to="#top">
          <ArrowUpwardIcon />
        </HashLink>
      </Paper>
      <Box>
        <Tabs
          sx={{
            height: "60px",
            paddingTop: "5px",
            marginBottom: "5px",
            marginX: "0px",
            paddingBottom: "10px",
          }}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab
            label="overview"
            sx={{
              textTransform: "capitalize",
              flexFlow: "row",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 3,
            }}
          />
          {/* <Tab sx={{ flexGrow: 1 }} disabled /> */}
          <Divider
            orientation="vertical"
            sx={{ height: "38px", borderColor: "#6f6f6f" }}
          />

          {/* <Tab sx={{ flexGrow: 1 }} disabled /> */}
          <Tab
            label="smart View"
            sx={{
              textTransform: "capitalize",
              flexGrow: 3,
              paddingX: "30px",
            }}
          />
        </Tabs>
      </Box>
      {selectedTab === 0 && <OverViewTabPanel {...props} />}
      {selectedTab === 2 && <SmartViewTabPanel {...props} />}
    </Box>
  );
}
