import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { CenterItems, CoveringCard } from "../common/PaperAbstraction";
import { SectionTitleWithIntro } from "../common/BoxAbstraction";
import ProfilePanelOverviewCards from "./ProfilePanelOverviewCards";
import ProfilePanelOverviewCardsDonut from "./ProfilePanelOverviewCardsDonut";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

export default function PanelOverview(props) {
  // console.log("🚀 ~ file: PanelOverview.js:15 ~ PanelOverview ~ props", props)
  const theme = useTheme();

  if(props?.concernAreaData?.length==0||!props?.concernAreaData)
  return <></>;

  return (
    <Paper
      square={true}
      elevation={0}
      // sx={{ bgcolor: theme.palette.other.cardBackground }}
    >
      <SectionTitleWithIntro
        // title="Let’s look at your test profiles"
        // text="Our algorithm suggests that you have a look at these"
        {...props.panelOverviewIntro}
        // sx={{ bgcolor: theme.palette.other.cardBackground }}
      />

      <CoveringCard elevation={0}>
        <Stack>
          {props.concernAreaData.map((concernArea, index) => (
            <ProfilePanelOverviewCards
              key={`${index}`}
              {...concernArea}
              assetPath={props.assetPath}
              lastIndex={index === props.concernAreaData - 1}
              id={concernArea.slug2}
            />
          ))}
        </Stack>
      </CoveringCard>
      <Divider style={{ width: "100%", height: "2px" }} variant="left" />
    </Paper>
  );
}
