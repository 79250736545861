import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

export const CenterItems = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  backgroundColor: "inherit",
  elevation: 0,
}));
export const CenterItemsColumn = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  direction: "column",
  backgroundColor: "inherit",
  elevation: 0,
}));

export const RightItems = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  alignContent: "flex-end",
  flexFlow: "row",
  flexWrap: "wrap",
  backgroundColor: "inherit",
}));
export const LeftItems = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  alignContent: "center",
  flexFlow: "row",
  flexWrap: "wrap",
  backgroundColor: "inherit",
}));

export const CoveringCard = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  elevation: 0,
  backgroundColor: "inherit",
}));

export const BaseCoveringCard = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: "inherit",
}));

export const ComponentCard = styled(Paper)(({ theme }) => ({
  square: true,
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  // backgroundColor: "#f2f2f2",
  elevation: 0,
  evertion: 0,
  backgroundColor: "inherit",
}));

export const ComponentCardNoColor = styled(Paper)(({ theme }) => ({
  square: true,
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  elevation: 0,
  evertion: 0,
  backgroundColor: "inherit",
}));

export function CoveringCardWithTitle(props) {
  const extraText = props.extraText || "";
  return (
    <CoveringCard {...props}>
      <Typography variant="h6">{props.title}</Typography>
      <Typography variant="p">{extraText}</Typography>
    </CoveringCard>
  );
}
