import React from "react";
import Box from "@mui/material/Box";
import BodySummary from "../components/BodySummary";
import PanelOverview from "../components/PanelOverview";
import RecommendSection from "../components/RecommendSection";
import FollowUpRecommendation from "../components/FollowUpRecommendation";
import { ComponentCard } from "../common/PaperAbstraction";
import { Button  } from "@mui/material";
import { HashLink } from "react-router-hash-link";


export default function OverView(props) {
  const handleChange = (event, newValue) => {
    let newTab = 2;
    if (newValue in props.tabNameToIndex) props.navigate(`/${props.tabNameToIndex[newValue]}`);
    if (newValue in props.indexToTabName) {
      newTab = props.indexToTabName[newValue];
      props.navigate(`/${newValue}`);
    }
    props.setSelectedTab(newTab);
  };
 
  // console.log("🚀 ~ file: OverView.js ~ line 9 ~ OverView ~ props", props);
  return (
    <ComponentCard sx={{ marginX: 1 }} elevation={0}>
      <BodySummary
        bodyData={props.bodyData}
        assetPath={props.assetPath}
        patientDetails={props.patientDetails}
        reportIntro={props.reportIntro}
      />
      <PanelOverview
        assetPath={props.assetPath}
        concernAreaData={props.concernAreaData}
        panelOverviewIntro={props.panelOverviewIntro}
      />
      <FollowUpRecommendation
        followUpRecommendation={props.followUpRecommendation}
        followUpRecommendationIntro={props.followUpRecommendationIntro}
      />
      <RecommendSection
        assetPath={props.assetPath}
        recommendationData={props.recommendationData}
        setSelectedTab={props.setSelectedTab}
        navigate={props.navigate}
        indexToTabName={props.indexToTabName}
        tabNameToIndex={props.tabNameToIndex}
        handleChange={props.handleChange}
        recommendationSectionIntro={props.recommendationSectionIntro}
      />
              <Box width={"100%"} paddingBottom={1} margin={0}>
          <HashLink smooth to="#">
            <Button onClick={handleChange} paddingBottom={2} fullWidth={true}>
              GO TO smart view
            </Button>
          </HashLink>
        </Box>
    </ComponentCard>
  );
}
