import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

export default function SimpleBackdrop(props) {
  console.log(
    "🚀 ~ file: BackdropLoader.js:7 ~ SimpleBackdrop ~ props:",
    props
  );
  const [open, setOpen] = useState(!props.currentState);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(!props.currentState);
  }, [props.currentState]);

  return (
    <div>
      {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        Loading, please wait &nbsp;
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
