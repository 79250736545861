import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

export const ReadMore = ({ children }) => {
  const theme = useTheme();
  const text = children;

  // console.log(
  //   "🚀 ~ file: TypographyAbstraction.js ~ line 9 ~ ReadMore ~ text",
  //   text
  // );
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  if (text.length < 70)
    return (
      <Paper elevation={0} margin={0} padding={0} sx={{ bgcolor: "inherit" }}>
        <Typography variant="body1" marginLeft={1}>
          {text}
        </Typography>
      </Paper>
    );

  return (
    <Paper elevation={0} margin={0} padding={0} sx={{ bgcolor: "inherit" }}>
      <Typography variant="body1" marginLeft={1} onClick={toggleReadMore}>
        {isReadMore ? text.slice(0, 70) : text}
        {isReadMore ? (
          <Typography
            component="span"
            sx={{ color: theme.palette.primary.dark, cursor: "pointer" }}
          >
            ...read more
          </Typography>
        ) : (
          <Typography
            component="span"
            sx={{ color: theme.palette.primary.dark, cursor: "pointer" }}
          >
            &nbsp;show less
          </Typography>
        )}
      </Typography>
    </Paper>
  );
};;
