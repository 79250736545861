import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ProfileAnalysis from "../components/ProfileAnalysis";
import AllProfile from "../components/AllProfile";
import SwitchProfileView from "../components/SwitchProfileView";
import SwitchProfileView2 from "../components/SwitchProfileView";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ComponentCard,
  ComponentCardNoColor,
} from "../common/PaperAbstraction";
import { useParams, useNavigate } from "react-router-dom";

export default function SmartView(props) {
  // console.log("🚀 ~ file: SmartView.js:16 ~ SmartView ~ props", props)
  let { id } = useParams();
  // console.log("🚀 ~ file: SmartView.js ~ line 16 ~ SmartView ~ id", id);
  return (
    <ComponentCardNoColor>
      <ProfileAnalysis
        profileAnalysisIntro={props.profileAnalysisIntro}
        carouselData={props.carouselData}
        assetPath={props.assetPath}
      />
      {/* <SwitchProfileView /> */}
      <AllProfile
        autoComplete={props.autoComplete}
        profileData={props.profileData}
        assetPath={props.assetPath}
        allProfileIntro={props.allProfileIntro}
        hash={props.hash}
      />
      {/* <SwitchProfileView2 /> */}
    </ComponentCardNoColor>
  );
}
