import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {
  CenterItems,
  CoveringCard,
  LeftItems,
  RightItems,
} from "../common/PaperAbstraction";
import { HashLink,NavHashLink } from "react-router-hash-link";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import parse from "html-react-parser";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import DoneIcon from "@mui/icons-material/Done";
import { useTheme } from "@mui/material/styles";

// LinearProgressWithLabel.propTypes = {
//   /**
//    * The value of the progress indicator for the determinate and buffer variants.
//    * Value between 0 and 100.
//    */
//   value: PropTypes.number.isRequired,
// };

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.mode === "light" ? "#0F9D58" : "#0F9D58",
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     elevation: 0,
//     backgroundColor: theme.palette.mode === "light" ? "#DB4437" : "#DB4437",
//   },
// }));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.other.slider.abnormal
        : "#DB4437",
  },
  [`& .${linearProgressClasses.bar}`]: {
    elevation: 0,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.other.slider.normal
        : "#0F9D58",
  },
}));
function LinearProgressWithLabel(props) {
  // console.log(
  //   "🚀 ~ file: ProfilePanelOverviewCards.js:62 ~ LinearProgressWithLabel ~ props",
  //   props
  // );
  const theme = useTheme();

  let checkBox = "";
  if (props.showCheckbox) checkBox = <DoneIcon />;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", maxWidth: "200px", mr: 2 }}>
        <BorderLinearProgress
          variant="determinate"
          {...props}
          sx={{
            borderRadius: 4,
            height: 8,
            bar1ColorPrimary: "#000",
            barColorSecondary: "#000",
          }}
        />
      </Box>
      <Box sx={{ minWidth: 130 }} alignContent="center" alignItems="center">
        <LeftItems elevation={0}>
          <Typography variant="body2" color="text.secondary">
            {`${props.barText}`}
          </Typography>
          {props.showCheckBox && (
            <DoneIcon
              sx={{
                padding: 0,
                marginX: 1,
                color: { color: theme.palette.other.slider.normal },
              }}
            />
          )}
        </LeftItems>
      </Box>
    </Box>
  );
}

// const sampleProp = {
//   id: 1,
//   heading: "Diabetes",
//   subHeading:
//     "Diabetes is a condition in which the blood glucose levels are too high.",
//   avatar:
//     "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
//   progressValue: 50,
//   progressBarText: "5/10",
//   subText: `Diabetes is a serious disease that can all of your body.Understanding it deeply can help you follow your treatment plan and stay as healthy as possible.`,
// };
export default function ProfilePanelOverviewCards(props) {
  // console.log(
  //   "🚀 ~ file: ProfilePanelOverviewCards.js:102 ~ ProfilePanelOverviewCards ~ props",
  //   props
  // );
  return (
    <>
      <Grid
        container
        spacing={2}
        marginBottom={2}
        marginTop={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={11}>
          <LeftItems elevation={0} id={props.slug2}>
            <Box sx={{ maxHeight: 40, maxWidth: 40, marginRight: 2 }}>
              <img
                src={`${props.assetPath}${props.avatar}`}
                alt="temp"
                variant="square"
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </Box>

            <Typography variant="h4">{props.heading}</Typography>
          </LeftItems>

          <Box marginLeft={7}>
            <LinearProgressWithLabel
              value={props.progressValue}
              barText={`${props.progressBarText}`}
              showCheckBox={props.showCheckBox}
            />
            {/* <LinearProgressWithLabel value={30} barText={"3/10"} /> */}
          </Box>
          <Box marginTop={1}>
            <Typography variant="p">{parse(props.subText)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <RightItems elevation={0}>
            <HashLink to={`/smartview#${props.slug}`} smooth>
              <Box sx={{ position: "relative", marginRight: 3 }}>
                <Brightness1OutlinedIcon
                  sx={{ position: "absolute", fontSize: "30px" }}
                  color="primary"
                  variant="light"
                />
                <KeyboardArrowRightRoundedIcon
                  sx={{ position: "absolute", fontSize: "30px" }}
                  color="primary"
                  variant="light"
                />
              </Box>
              {/* <Typography variant="url">Learn more</Typography> */}
            </HashLink>
          </RightItems>
        </Grid>
      </Grid>
    </>
  );
}
