import { convertToSlug } from "./utils";

export function dataHelper(input) {
  console.log(input);
}
export function bodySummaryHelper(input, indicatorColorPallet) {
  // console.log(input);

  const bodySummaryArray = input.map((item) => {
    let data = item.profile.status,
      signalColour = indicatorColorPallet.default;
    if (data === "Normal") {
      signalColour = indicatorColorPallet.normal;
    } else if (data === "Borderline") {
      signalColour = indicatorColorPallet.borderline;
    } else if (data === "Abnormal") {
      signalColour = indicatorColorPallet.abnormal;
    }

    const obj = {
      name: item.profile.name,
      status: item.profile.status,
      image: item.profile.svg,
      signalColour: signalColour,
      slug2: item.profile.profileNameSlug2,
    };
    obj.pieChartData = {
      label: ["Abnormal", "Normal"],
      datasets: [
        {
          label: obj.name,
          data: [
            item.profile.abnormalBiomarkerCount,
            item.profile.normalBiomarkerCount,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            // "rgba(54, 162, 235, 0.2)",
            // "rgba(255, 206, 86, 0.2)",
            // "rgba(153, 102, 255, 0.2)",
            // "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(75, 192, 192, 1)",
            // "rgba(54, 162, 235, 1)",
            // "rgba(255, 206, 86, 1)",
            // "rgba(153, 102, 255, 1)",
            // "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    return obj;
  });
  // console.log(
  //   "🚀 ~ file: DataHelper.js ~ line 16 ~ bodySummaryHelper ~ bodySummaryArray",
  //   JSON.stringify(bodySummaryArray)
  // );

  return bodySummaryArray;
}

export function autoCompleteHelper(input) {
  // console.log(input);
  const autoCompleteData = [];
  input.forEach((item, profileIdx) => {
    const obj = {
      name: item.profile.name,
      slug: item.profile.profileNameSlug,
    };
    // autoCompleteData.push(obj);
    item.biomarker.forEach((test) => {
      const testObj = {
        name: test?.testName,
        slug: test?.testNameSlug,
        profileName: item.profile.name,
        profileNameSlug: item.profile.profileNameSlug,
        // slug: `${item.profile.profileNameSlug}__${test?.testNameSlug}`,
      };
      autoCompleteData.push(testObj);
    });
  });

  // console.log(
  //   "🚀 ~ file: DataHelper.js ~ line 42 ~ autoCompleteHelper ~ autoCompleteData",
  //   autoCompleteData
  // );
  return autoCompleteData;
}

function getTestColor(status, indicatorColorPallet) {
  //  console.log("🚀 ~ file: DataHelper.js:95 ~ getTestColor ~ status", status)
  let indicatorColor = "#00000000";
  if (status === "normal") indicatorColor = indicatorColorPallet.normal;
  else if (status == "finalCritical")
    indicatorColor = indicatorColorPallet.abnormal;
  else if (status == "finalCritial")
    indicatorColor = indicatorColorPallet.abnormal;
  else if (status == "oneFromNormal")
    indicatorColor = indicatorColorPallet.borderline;
  else if (status == "twoFromNormal")
    indicatorColor = indicatorColorPallet.borderline;
  else indicatorColor = "#00000000";
  return indicatorColor;
}
const extractFirstNumber = (number) => {
  if (!number) return undefined;
  const num = parseFloat(number.match(/-?\d+\.?\d*/));
  return num;
};

const getNumericValueOrFalse = (item, precision) => {
  if (!item || item == "-") return undefined;
  if (typeof item == typeof 0) return item;
  try {
    let num = item.replace(/,/g, "");
    num = extractFirstNumber(num, precision);
    if (!num) num = item;
    return num;
  } catch (e) {
    console.log(e, e.stack, "\n", item);
    return item;
  }
};

function updateRanges(testObj) {
  if (
    testObj?.minParameterValue &&
    testObj?.maxParameterValue &&
    (testObj?.rangeObj?.sliderType == "LN" ||
      testObj?.rangeObj?.sliderType == "NH")
  )
    testObj.rangeObj.sliderType = "LNH";
  if (
    testObj.rangeObj.sliderType === "LNH" &&
    (testObj.rangeObj.lowThreshold === "0" ||
      testObj.rangeObj.lowThreshold === 0)
  ) {
    testObj.rangeObj.sliderType = "NH";
  }
  if (testObj?.rangeObj?.lowThreshold)
    testObj.rangeObj.lowThreshold = getNumericValueOrFalse(
      testObj?.rangeObj?.lowThreshold
    );
  if (testObj?.rangeObj?.boderline)
    testObj.rangeObj.boderline = getNumericValueOrFalse(
      testObj?.rangeObj?.boderline
    );
  if (testObj?.rangeObj?.highThreshold)
    testObj.rangeObj.highThreshold = getNumericValueOrFalse(
      testObj?.rangeObj?.highThreshold
    );
}

export function profileDataHelper(input, indicatorColorPallet) {
  // console.log(input);
  let profileData = input?.resultData;
  profileData?.forEach((ele) => {
    ele.profile.profileNameSlug = convertToSlug(ele?.profile?.name);
    if (ele.profile.profileNameSlug === "all-other-tests") {
      ele.profile.simpleProfileView = true;
      ele.profile.simpleTableView = true;
    }
    ele.profile.profileNameSlug2 = `${convertToSlug(ele?.profile?.name)}_2`;

    ele.biomarker.forEach((test) => {
      test.testNameSlug = `${convertToSlug(test?.testName)}`;
      test.simpleSlug = `${convertToSlug(test?.testName)}`;
      test.signalColor = getTestColor(
        test?.colorIndicator,
        indicatorColorPallet
      );
      updateRanges(test);
    });
    if (ele?.profile?.svg === "blank.svg" || !ele?.profile?.svg)
      ele.profile.svg = "";
  });
  // console.log(input);

  profileData = profileData.sort((profileA, profileB) => {
    if (profileA?.profile?.simpleProfileView) return 1;
    else if (profileB?.profile?.simpleProfileView) return -1;
    return 0;
  });

  return profileData;
}

export function recommendationDataHelper(input) {
  const recommendationDataArray = input.map((item) => {
    const obj = {
      status: item.profile.status,
      text: item.profile.data.recommendation,
    };
    return obj;
  });
  let recommendationData = recommendationDataArray.filter(
    (item) => item.status !== "Normal" && item.text !== ""
  );
  // console.log(
  //   "🚀 ~ file: DataHelper.js ~ line 32 ~ recommendationDataHelper ~ recommendationData",
  //   recommendationData
  // );
  // if (recommendationData.length === 0)
  //   recommendationData = recommendationDataArray.slice(0, 2);
  return recommendationData;
}

export function followUpRecommendationDataHelper(input) {
  let data = [];
  if (input?.recommendationData) data = input.recommendationData;
  return data;
}

export function patientDetailsDataHelper(input) {
  let data = {
    name: "John Doe",
    age: "30",
    location: "New York, NY",
    gender: "Male",
    pid: "ABCTest123",
  };
  if (input?.reportData) {
    data.name = input?.reportData?.patientName;
    data.age = input?.reportData?.paitentAge;
    data.gender = input?.reportData?.paitentGender;
    data.pid = input?.reportData?.labId;
    data.sampleId = input?.reportData?.sampleId;
  }
  return data;
}

function getConcernSliderText(abnormalCount, normalCount) {
  if (abnormalCount == 0) return [`All Good (${normalCount + abnormalCount})`];
  return `${abnormalCount} /
          ${normalCount + abnormalCount} Concern`;
}

export function concernAreaDataHelper(input) {
  const concernAreaDataArray = input.map((item) => {
    const obj = {};
    obj.heading = item.profile.name;
    obj.subText = item.profile.data.concernArea;
    obj.percentageNormal = 100;
    if (
      item.profile.normalBiomarkerCount !== 0 ||
      item.profile.abnormalBiomarkerCount !== 0
    )
      obj.percentageNormal =
        (item.profile.normalBiomarkerCount /
          (item.profile.normalBiomarkerCount +
            item.profile.abnormalBiomarkerCount)) *
        100;
    obj.status = item.profile.status;
    // obj.progressValue = 100 - obj.percentageNormal;
    obj.progressValue = obj.percentageNormal;
    obj.showCheckBox = false;
    if (obj.percentageNormal === 100) obj.showCheckBox = true;

    obj.avatar = item.profile.svg;
    obj.slug2 = item.profile.profileNameSlug2;
    obj.slug = item.profile.profileNameSlug;
    obj.progressBarText = getConcernSliderText(
      item.profile.abnormalBiomarkerCount,
      item.profile.normalBiomarkerCount
    );
    obj.pieChartData = {
      label: ["Abnormal", "Normal"],
      datasets: [
        {
          label: obj.heading,
          data: [
            item.profile.abnormalBiomarkerCount,
            item.profile.normalBiomarkerCount,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            // "rgba(54, 162, 235, 0.2)",
            // "rgba(255, 206, 86, 0.2)",
            // "rgba(153, 102, 255, 0.2)",
            // "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(75, 192, 192, 1)",
            // "rgba(54, 162, 235, 1)",
            // "rgba(255, 206, 86, 1)",
            // "rgba(153, 102, 255, 1)",
            // "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    return obj;
  });

  concernAreaDataArray.sort((a, b) => a.percentageNormal >= b.percentageNormal);

  const finalConcernAreaDataArray = concernAreaDataArray.filter(
    (ele) => ele.subText !== ""
  );

  // return finalConcernAreaDataArray.slice(0, 3);
  return finalConcernAreaDataArray;
  // [
  //   {
  //     heading: "Diabetes",
  //     subHeading:
  //       "Diabetes is a condition in which the blood glucose levels are too high.",
  //     avatar:
  //       "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
  //     progressValue: 50,
  //     progressBarText: "5/10",
  //     subText: `Diabetes is a serious disease that can all of your body.Understanding it deeply can help you follow your treatment plan and stay as healthy as possible.`,
  //   },
  //   {
  //     heading: "Diabetes",
  //     subHeading:
  //       "Diabetes is a condition in which the blood glucose levels are too high.",
  //     avatar:
  //       "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
  //     progressValue: 50,
  //     progressBarText: "5/10",
  //     subText: `Diabetes is a serious disease that can all of your body.Understanding it deeply can help you follow your treatment plan and stay as healthy as possible.`,
  //   },
  //   {
  //     heading: "Diabetes",
  //     subHeading:
  //       "Diabetes is a condition in which the blood glucose levels are too high.",
  //     avatar:
  //       "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
  //     progressValue: 50,
  //     progressBarText: "5/10",
  //     subText: `Diabetes is a serious disease that can all of your body.Understanding it deeply can help you follow your treatment plan and stay as healthy as possible.`,
  //   },
  // ];
}
